jQuery(document).ready(function ($) {
  var $externalLink = $('.js-external-link');

  $('.clickable-banner').off('click');
  $('.clickable-banner').click(function (e) {
    e.preventDefault();
    if ($(this).attr('data-clickable').value !== '') {
      window.open($(this).data('clickable'), '_blank');
    }
  });
  $externalLink.once().on('click', function (e) {
    e.preventDefault();
    $(document).trigger('remainOnAvedaOverlay', $(this).attr('href'));
  });
});
(function (lazyTwik) {
  lazyTwik.init = function () {
    $.each($('.js_content-block-large__image'), function (i, el) {
      var $image = $(el).find('picture img').first();

      if (!$image.length) {
        $(el).hide();
      }
      if (!$image.hasClass('lazyload')) {
        // if the image was allready loaded by lazyload we don't need to wait for the event.
        $(el).addClass('content-block-large__image__auto-height');
      } else {
        $image.on('lazybeforeunveil', function () {
          $(el).addClass('content-block-large__image__auto-height');
        });
      }
    });
  };
  lazyTwik.init();
})(window.lazyTwik || {});
